@import "variables";

@layer typography {
    // ------------------------------- Text -------------------------------
    @each $key, $val in $font-size {
        .text-#{$key} { font-size: $val; }
    }
    
    .font-bold { font-weight: bold; }
    .font-italic { font-style: italic; }
    .underline { text-decoration: underline; }
    
    html {
        font-family: 'Lora', sans-serif;
        @extend .text-3;
    }
    .font-display {
        font-family: 'Signika Negative', sans-serif;   
    }
    
    @mixin header_commons {
        @extend .font-display;
        line-height: 1.5;
    }
    .header-1 { @include header_commons; @extend .font-bold; @extend .text-7; }
    .header-2 { @include header_commons; @extend .font-bold; @extend .text-6; }
    .header-3 { @include header_commons; @extend .text-6; }
    .header-4 { @include header_commons; @extend .font-italic;  @extend .text-5; }
    .header-5 { @include header_commons; @extend .text-5; }
    .header-6 { @include header_commons; @extend .font-bold; @extend .text-4; }
    .header-7 { @include header_commons; @extend .font-bold; @extend .text-0; }
    
    .text-center { text-align: center; }

    // ------------------------------- Lists -------------------------------
    .list {
        &.list-disc { list-style-type: disc; }
        &.list-decimal { list-style-type: decimal; }
    }
}