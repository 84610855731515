@use "sass:map";
@use "sass:color";
@import "variables";
@import "_mixins";

@layer components {
  .link {
    // text-decoration: underline;
    text-decoration: none;
    @extend .text-accent-100;
    @extend .font-display;
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-thickness: 1px;

    &:visited {
      @extend .text-accent-100;
      @extend .opacity-2;
    }

    &:hover {
      text-decoration-style: dashed;
    }
  }

  .cursor-pointer { cursor: pointer; }
  .cursor-default { cursor: default; }
  .cursor-not-allowed { cursor: not-allowed; }

  .btn {
    @extend .px-2;
    @extend .py-1;
    @extend .shadow-1;
    @extend .radius-1;
    cursor: pointer;
  }

  .btn-base-100 {
    background-color: var(--color-base-100-bg);
    color: var(--color-base-100-fg);
  }

  .btn-base-200 {
    background-color: var(--color-base-200-bg);
    color: var(--color-base-200-fg);
  }

  .btn-base-300 {
    background-color: var(--color-base-300-bg);
    color: var(--color-base-300-fg);
  }

  .btn-accent-200 {
    background-color: var(--color-accent-200);
    color: var(--color-base-300-fg);
  }

  .btn-lg {
    @extend .px-4;
    @extend .py-3;
  }

  // --------------------- Container ---------------------

  // padding-left: 100px;
  $content-sizes: (
    sm: (container-width: 700px,  padding: 10px),
    md: (container-width: 800px,  padding: 0),
    lg: (container-width: 800px,  padding: 0),
    xl: (container-width: 800px,  padding: 0)
  );

  // this is a top-level container class that can be used to wrap a component.
  // the component will then be sized in a way that is consistent with the rest of the app.
  @each $size-key, $size-value in $content-sizes {
    $padding: map-get($size-value, padding);
    $container-width: map-get($size-value, container-width);

    @include for-media($size-key) {
      .container-wrapper {
        width: 100%;

        .container {
          width: 100%;
          margin: auto;
          max-width: $container-width;
          padding-left: $padding;
          padding-right: $padding;
        }
      }
    }
  }

  .table {
    @extend .bg-base-300;
    @extend .shadow-4;
    @extend .radius-1;
    width: 100%;
    border: 1px solid var(--color-base-300-bg);
    border-spacing: 2px;
    border-collapse: separate;
    
    th, td {
      @extend .py-2;
      @extend .px-3;
    }

    td {
      @extend .bg-base-100;
    }

    th {
      @extend .base-200;
      font-weight: bold;
    }
  }
}