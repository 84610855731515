@use "sass:map";
@use "sass:color";
@import "variables";

@layer reset {
  /* http://meyerweb.com/eric/tools/css/reset/ 
    v2.0 | 20110126
    License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    text-align: unset;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1.5;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  // remove text decoration from links
  a {
  
    text-decoration: inherit;
    color: inherit; 
    
    &:visited {
        color: inherit;
    }
    &:hover {
        text-decoration: inherit;
    }
  }

  html {
    box-sizing: border-box;
 }

  *, *::before, *::after {
      box-sizing: inherit;
  }

  select {
    cursor: pointer;
    @extend .text-3;
  }

  input[type="range"] {
    cursor: pointer;
  }

  summary {
    cursor: unset;
  }

  ::selection {
    background-color: color.adjust(map-get($colors, "accent-200"), $alpha: -0.6);
    opacity: 0;
    padding: 0;
    margin: 0;
  } 
}