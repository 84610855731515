@use "sass:map";

$font-size: (
    0: 0,
    1: .75rem,
    2: .875rem,
    3: 1rem,
    4: 1.1rem,
    5: 1.3rem,
    6: 1.5rem,
    7: 2rem
);

$spacing: (
    0: 0,
    1: .25rem,
    2: .5rem,
    3: 1rem,
    4: 2rem,
    5: 4rem,
    6: 8rem,
    7: 16rem
);

$colors: (
    base-100-bg: #e6dddd,
    base-100-fg: #292828,
    base-200-bg: #c5bebe,
    base-200-fg: #292828,
    base-300-bg: #312e2e,
    base-300-fg: #d3caca,
    secondary: #6c757d,
    success: #28a745,
    error: #dc3545,
    warning: #ffc107,
    info: #17a2b8,
    light: #f8f9fa,
    dark: #343a40,
    accent-100: rgb(48, 138, 78),
    accent-200: rgb(165, 110, 184)
);

$color-archetypes: (
    base-100: (
        background: map.get($colors, "base-100-bg"),
        text: map.get($colors, "base-100-fg"),
    ),
    base-200: (
        background: map.get($colors, "base-200-bg"),
        text: map.get($colors, "base-200-fg"),
    ),
    base-300: (
        background: map.get($colors, "base-300-bg"),
        text: map.get($colors, "base-300-fg"),
    ),
);

$radius: (
    0: 0,
    1: .25rem,
    2: .5rem,
    3: 1rem,
    4: 2rem,
    5: 4rem,
    6: 8rem,
    7: 16rem
);

$percentages: (
    0: 0,
    1: 0.14,
    2: 0.28,
    3: 0.42,
    4: 0.57,
    5: 0.71,
    6: 0.85,
    7: 100
)