@layer markdown {
  .markdown {
    // inline styles
    @extend .flex;
    @extend .flex-col;
    gap: 1.6rem;
    line-height: 1.50;

    // --------------------- Typography ---------------------
    h1, h2, h3, h4, h5, h6 {
      margin-top: 0.5rem;
    }
    h1 { @extend .header-1; @extend .font-bold; }
    h2 { @extend .header-2; }
    h3 { @extend .header-3; }
    h4 { @extend .header-4; }
    h5 { @extend .header-5; }
    h6 { @extend .header-6; }
    p { @extend .text-4; }
    strong { @extend .font-bold; }
    em { @extend .font-italic; }

    //  --------------------- Lists ---------------------
    ul, ol {
      @extend .text-4;
      padding-left: 1.5rem;
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }


    a {
      @extend .link;
    }

    blockquote {
      @extend .base-200;
      border-left: 8px solid var(--color-base-300-bg);
      // @extend .bg-gray-100;
      // @extend .border-l-4;
      // @extend .border-gray-400;
      @extend .px-3;
      @extend .py-3;
      @extend .text-4;
      @extend .font-italic;
      @extend .shadow-2;

      & blockquote {
        @extend .my-2;
        @extend .shadow-6;
        
      }
    }

    // table formatting in vanilla css, without using extends
    table {
      @extend .table;
      @extend .mb-2;
    }

    hr {
      border: 0;
      border-top: 1px solid var(--color-base-300-bg);
      margin: 1.5rem 0;
    }

    .section-nav {
      a {
        color: inherit;
      }
    }

    .highlighter-rouge {
      @extend .px-1;
      @extend .py-1;
      @extend .bg-base-300;
      @extend .text-base-300;
      @extend .radius-1;
      @extend .text-4;
      font-family: "Fira Code", "Source Code Pro", "Consolas", "Inconsolata", "Courier New", "monospace";
      word-break: break-all;

      pre {
        word-break: keep-all;
        white-space: pre-wrap;
      }

      
    }
    div.highlighter-rouge {
      @extend .shadow-6;
    }


    // Output from `rougify style monokai.sublime > _sass/code.scss`
    .highlight {
      font-family: "Fira Code", "Source Code Pro", "Consolas", "Inconsolata", "Courier New", "monospace";
      font-size: 0.9em;
      line-height: 1.5;
      color: #f8f8f2;
      background-color: #272822;
      @extend .py-1;
      @extend .px-2;
      border-radius: 5px;
      overflow: clip;
    }

    .highlight table td {
      padding: 5px;
    }

    .highlight table pre {
      margin: 0;
    }

    .highlight .gh {
      color: #999999;
    }

    .highlight .sr {
      color: #f6aa11;
    }

    .highlight .go {
      color: #888888;
    }

    .highlight .gp {
      color: #555555;
    }

    .highlight .gs {}

    .highlight .gu {
      color: #aaaaaa;
    }

    .highlight .nb {
      color: #f6aa11;
    }

    .highlight .cm {
      color: #75715e;
    }

    .highlight .cp {
      color: #75715e;
    }

    .highlight .c1 {
      color: #75715e;
    }

    .highlight .cs {
      color: #75715e;
    }

    .highlight .c,
    .highlight .ch,
    .highlight .cd,
    .highlight .cpf {
      color: #75715e;
    }

    .highlight .err {
      color: #960050;
    }

    .highlight .gr {
      color: #960050;
    }

    .highlight .gt {
      color: #960050;
    }

    .highlight .gd {
      color: #49483e;
    }

    .highlight .gi {
      color: #49483e;
    }

    .highlight .ge {
      color: #49483e;
    }

    .highlight .kc {
      color: #66d9ef;
    }

    .highlight .kd {
      color: #66d9ef;
    }

    .highlight .kr {
      color: #66d9ef;
    }

    .highlight .no {
      color: #66d9ef;
    }

    .highlight .kt {
      color: #66d9ef;
    }

    .highlight .mf {
      color: #ae81ff;
    }

    .highlight .mh {
      color: #ae81ff;
    }

    .highlight .il {
      color: #ae81ff;
    }

    .highlight .mi {
      color: #ae81ff;
    }

    .highlight .mo {
      color: #ae81ff;
    }

    .highlight .m,
    .highlight .mb,
    .highlight .mx {
      color: #ae81ff;
    }

    .highlight .sc {
      color: #ae81ff;
    }

    .highlight .se {
      color: #ae81ff;
    }

    .highlight .ss {
      color: #ae81ff;
    }

    .highlight .sd {
      color: #e6db74;
    }

    .highlight .s2 {
      color: #e6db74;
    }

    .highlight .sb {
      color: #e6db74;
    }

    .highlight .sh {
      color: #e6db74;
    }

    .highlight .si {
      color: #e6db74;
    }

    .highlight .sx {
      color: #e6db74;
    }

    .highlight .s1 {
      color: #e6db74;
    }

    .highlight .s,
    .highlight .sa,
    .highlight .dl {
      color: #e6db74;
    }

    .highlight .na {
      color: #a6e22e;
    }

    .highlight .nc {
      color: #a6e22e;
    }

    .highlight .nd {
      color: #a6e22e;
    }

    .highlight .ne {
      color: #a6e22e;
    }

    .highlight .nf,
    .highlight .fm {
      color: #a6e22e;
    }

    .highlight .vc {
      color: #ffffff;
      background-color: #272822;
    }

    .highlight .nn {
      color: #ffffff;
      background-color: #272822;
    }

    .highlight .nl {
      color: #ffffff;
      background-color: #272822;
    }

    .highlight .ni {
      color: #ffffff;
      background-color: #272822;
    }

    .highlight .bp {
      color: #ffffff;
      background-color: #272822;
    }

    .highlight .vg {
      color: #ffffff;
      background-color: #272822;
    }

    .highlight .vi {
      color: #ffffff;
      background-color: #272822;
    }

    .highlight .nv,
    .highlight .vm {
      color: #ffffff;
      background-color: #272822;
    }

    .highlight .w {
      color: #ffffff;
      background-color: #272822;
    }

    .highlight {
      color: #ffffff;
      background-color: #272822;
    }

    .highlight .n,
    .highlight .py,
    .highlight .nx {
      color: #ffffff;
      background-color: #272822;
    }

    .highlight .ow {
      color: #f92672;
    }

    .highlight .nt {
      color: #f92672;
    }

    .highlight .k,
    .highlight .kv {
      color: #f92672;
    }

    .highlight .kn {
      color: #f92672;
    }

    .highlight .kp {
      color: #f92672;
    }

    .highlight .o {
      color: #f92672;
    }

  }

  .language-mermaid {
    display: inline-block;
    width: 100%;
    margin: auto;
    text-align: center;

    svg {
      margin: auto;
    }
  }

}